import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-clovis-california.png'
import image0 from "../../images/cities/scale-model-of-fac-water-tower-in-clovis-california.png"
import image1 from "../../images/cities/scale-model-of-arte-américas-in-clovis-california.png"
import image2 from "../../images/cities/scale-model-of-clovis---big-dry-creek-museum-in-clovis-california.png"
import image3 from "../../images/cities/scale-model-of-meux-home-museum-in-clovis-california.png"
import image4 from "../../images/cities/scale-model-of-fresno-art-museum-in-clovis-california.png"
import image5 from "../../images/cities/scale-model-of-clovis-tourist-info-&-visitors-in-clovis-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Clovis'
            state='California'
            image={image}
            lat='36.8282'
            lon='-119.6849'
            attractions={ [{"name": "FAC Water Tower", "vicinity": "FAC Water Tower, 2444 Fresno St, Fresno", "types": ["point_of_interest", "establishment"], "lat": 36.7392069, "lng": -119.78722170000003}, {"name": "Arte Am\u00e9ricas", "vicinity": "1630 Van Ness Ave, Fresno", "types": ["art_gallery", "museum", "store", "point_of_interest", "establishment"], "lat": 36.7411456, "lng": -119.795792}, {"name": "Clovis - Big Dry Creek Museum", "vicinity": "401 Pollasky Ave, Clovis", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.8251413, "lng": -119.70188480000002}, {"name": "Meux Home Museum", "vicinity": "1007 R St, Fresno", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.739746, "lng": -119.78239050000002}, {"name": "Fresno Art Museum", "vicinity": "2233 N First St, Fresno", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.7708142, "lng": -119.77396010000001}, {"name": "Clovis Tourist Info & Visitors", "vicinity": "399 Clovis Ave, Clovis", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 36.8254436, "lng": -119.70040870000003}] }
            attractionImages={ {"FAC Water Tower":image0,"Arte Américas":image1,"Clovis - Big Dry Creek Museum":image2,"Meux Home Museum":image3,"Fresno Art Museum":image4,"Clovis Tourist Info & Visitors":image5,} }
       />);
  }
}